<template>
  <div class="hamburger-menu">
    <div class="hamburger-menu__content">
      <ul class="hamburger-menu__navigation-links level0">
        <li v-for="item in getCategoryVisible(items)" :key="item.id" class="level0">
          <template v-if="getCategoryVisible(item.childrenData)">
            <div class="nav-link-text has-children" v-b-toggle="`menu-acc.${item.id}`">
              <span class="link-label">
                {{item.name}}
              </span>
              <i class="icomoon-icon"></i>
            </div>
            <b-collapse :id="`menu-acc.${item.id}`" accordion="hamburger-menu" @shown="scrollTo(`menu-acc.${item.id}`)" @hidden="resetNavigationHide()">
              <ul class="level1">
                <li class="level1"
                v-for="child in sortedCat(getCategoryVisible(item.childrenData), ['position'])"
                :key="child.url_path">
                  <!-- sub accordion -->
                  <template v-if="getCategoryVisible(child.childrenData).length">
                    <div class="nav-link-text has-children" v-b-toggle="`menu-sub-acc.${child.id}`">
                      <span class="link-label">
                        {{child.name}}
                      </span>
                    </div>
                    <!-- <ul class="level2"> -->
                    <ul class="level2" :class="{ pluscategory: getCategoryVisible(child.childrenData).length > numberCategoryHide && listCategoryHide.includes(child.id), hiddenVoice: !navigationHide.includes(child.id)}">
                      <li class="level2" v-for="subDeep in sortedCat(getCategoryVisible(child.childrenData), ['position'])" :key="subDeep.url_path">
                        <div>
                          <router-link class="nav-link-text" :class="{'view-all-link': isViewAllLink(subDeep.name), 'category-attributes': getCategoryImage(subDeep)}" :to="'listing' | localizedByNameCategories(subDeep.url_path, null, $store, $router)">
                            <div v-if="getCategoryImage(subDeep)" :style="{'background-image': `url(${getCategoryImage(subDeep)}`}" class="category-attributes__image"></div>
                            <span class="link-label"
                            :key="subDeep.url_path"
                            >{{ subDeep.name }}</span>
                          </router-link>
                        </div>
                      </li>
                      <li class="navbar-item level2" v-if="getCategoryVisible(child.childrenData).length > numberCategoryHide && !navigationHide.includes(child.id) && listCategoryHide.includes(child.id)">
                        <div>
                          <a class="nav-link-text view-all-link" @click="changeHiddenVoice(child.id)">
                            <span class="link-label">{{$t('viewall')}}</span>
                          </a>
                        </div>
                      </li>
                    </ul>
                  </template>
                  <!-- single link -->
                  <router-link v-else class="nav-link-text" :to="'listing' | localizedByNameCategories(child.url_path, child, $store, $router)">
                    {{child.name}}
                  </router-link>
                </li>
              </ul>
            </b-collapse>
          </template>
          <router-link v-else :to="'listing' | localizedByNameCategories(item.url_path, item, $store, $router)" class="nav-link-text">
            <span class="link-label">{{item.name}}</span>
          </router-link>
        </li>
      </ul>
      <internal-link></internal-link>
    </div>
    <div class="hamburger-menu__footer">
      <ul class="hamburger-menu__navigation-links__footer">
        <li class="level0">
          <button class="btn btn-none bcm-link bcm-link-bolded language-item" @click.prevent="goToLocale">
            <i class="icomoon-icon icon-world nav-icon"></i> {{locale}}
          </button>
        </li>
        <li class="level0">
          <a :href="`telto:${phoneNumber}`" class="btn btn-none bcm-link bcm-link-bolded bcm-link-icon-text">
            <i class="icomoon-icon icon-phone"></i>
            <span class="text ml-1">{{phoneNumber}}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import { mapGetters } from 'vuex'
import { EventBus } from '@/helpers'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
/* import uniqBy from 'lodash/uniqBy' */
import filter from 'lodash/filter'
import InternalLink from './InternalLink'
import Search from './Search'
import Config from 'config'

export default {
  name: 'HamburgerMenu',
  data () {
    return {
      phoneNumber: Config.Theme.needHelpNumber,
      baseUrl: process.env.VUE_APP_MAGENTO,
      hiddenVoice: true,
      navigationHide: []
    }
  },
  computed: {
    ...mapGetters({
      items: 'ui/menu',
      activeCategoryList: 'category/activeCategoryList'
    }),
    locale () {
      return `${this.$store.getters['ui/getCountry']}`
      // return `${this.$store.getters['storeConfig/language']}`
      // return this.$store.getters['ui/getCurrentLang']
      // return `${this.$t('baseLang')}`
    },
    labelHiddenVoice () {
      if (this.hiddenVoice) {
        return this.$t('viewall')
      }
      return ''
    },
    listCategoryHide () {
      return Config.Theme.navigationHideVoiceMobile
    },
    numberCategoryHide () {
      return Config.Theme.navigationLimitHideVoiceMobile
    }
  },
  methods: {
    getCategoryVisible (categories) {
      if (categories && categories.length) {
        return filter(categories, { include_in_menu: true, is_active: true })
      }
    },
    sortedCat (cat, ord) {
      return sortBy(cat, [`${ord}`])
    },
    closeRightBar () {
      this.$store.commit('ui/setRightActiveComponent', {})
    },
    scrollTo (target) {
      let brandNav = $(this.$refs.hamburgerNavigationBrand).outerHeight()
      target = $(`[aria-controls="${target}"]`)[0]
      EventBus.$emit('LeftNavigation:scrollTo', { el: target, margin: brandNav })
    },
    goToLocale () {
      const componentToLoad = import('theme/components/Language/ChooseLanguage')
      return this.$store.dispatch('ui/loadLeftActiveComponent', {
        component: componentToLoad,
        props: {
          headerTitle: this.$t('hamburgerMenu.language')
        }
      })
    },
    isViewAllLink (name) {
      return (name.toLowerCase().indexOf('view all') >= 0 || name.toLowerCase().indexOf('mostra tutto') >= 0 || name.toLowerCase().indexOf('vedi tutto') >= 0)
    },
    getCategoryImage (cat) {
      let imageUrl
      // image = find(cat.custom_attributes, { 'attribute_code': 'image' })
      if (this.isWorldOf && this.category.featured_img) {
        imageUrl = this.baseUrl + '/media/' + this.category.featured_img
      } else {
        const mediaCategoryPath = '/media/catalog/category/'
        let image = find(cat.custom_attributes, { 'attribute_code': 'image' })
        if (image && cat.level === 4) {
          if (!image.value.includes(mediaCategoryPath)) {
            imageUrl = this.baseUrl + mediaCategoryPath + image.value
          } else {
            imageUrl = this.baseUrl + '/' + image.value
          }
        }
      }
      return imageUrl
    },
    changeHiddenVoice (id) {
      this.hiddenVoice = !this.hiddenVoice
      this.navigationHide.push(id)
    },
    resetNavigationHide () {
      this.navigationHide = []
    }
  },
  components: {
    InternalLink,
    Search
  }
}
</script>
