<template>
  <div class="hamburger-menu__internal-links">
    <ul class="hamburger-menu__internal-links-nav">
      <li class="level0">
        <button class="btn btn-none bcm-link bcm-link-bolded" @click.prevent="goToAccountPage">
          <!-- <i class="icomoon-icon icon-user"></i> -->
          <svg class="o-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <title>admin</title>
            <path fill="none" stroke-linejoin="miter" stroke-linecap="butt" stroke-miterlimit="4" stroke-width="1.6842" stroke="#000" d="M23.030 9.567c0 3.852-3.123 6.975-6.975 6.975s-6.975-3.123-6.975-6.975c0-3.852 3.123-6.975 6.975-6.975s6.975 3.123 6.975 6.975z"></path>
            <path fill="none" stroke-linejoin="miter" stroke-linecap="butt" stroke-miterlimit="4" stroke-width="1.6842" stroke="#000" d="M16.055 18.032c3.824 0 7.309 1.333 9.928 3.524 2.409 2.015 4.084 4.759 4.599 7.853v0h-29.054c0.515-3.094 2.191-5.837 4.599-7.853 2.619-2.191 6.104-3.524 9.928-3.524z"></path>
          </svg>
          <span class="text">
            {{ $t('hamburgerMenu.myAccount') }}
          </span>
        </button>
      </li>
      <li class="level0">
        <router-link :to="{name: 'wishlist'}" class="bcm-link bcm-link-bolded">
          <i class="icomoon-icon icon-heart-wishlist"></i>
          <span class="text">{{ $t('hamburgerMenu.wishlist') }}</span>
        </router-link>
      </li>
      <li class="level0">
        <div class="nav-link-text" v-b-toggle="'customer-care-menu'">
          <span class="link-label">
            <i class="icomoon-icon icon-headphones"></i> {{ $t('hamburgerMenu.customerCare') }}
          </span>
          <i class="icomoon-icon"></i>
        </div>
        <b-collapse id="customer-care-menu" accordion="customer-care">
          <ul class="level1">
            <li class="level1">
              <button class="btn btn-none nav-link-text has-children customer-care-item" @click.prevent="goToContactUs">
                <span class="link-label">{{ $t('hamburgerMenu.contactUs') }}</span>
              </button>
            </li>
            <li class="level1">
              <router-link class="nav-link-text has-children" :to="'faq'">
                <span class="link-label">{{ $t('hamburgerMenu.faq') }}</span>
              </router-link>
            </li>
            <li class="level1">
              <router-link class="nav-link-text has-children" :to="'book-an-appointment'">
                <span class="link-label">{{ $t('hamburgerMenu.BookAnAppointment') }}</span>
              </router-link>
            </li>
            <li class="level1">
              <router-link class="nav-link-text has-children" :to="'personal-shopper'">
                <span class="link-label">{{ $t('hamburgerMenu.FashionExpert') }}</span>
              </router-link>
            </li>
            <li class="level1">
              <router-link class="nav-link-text has-children" :to="'follow-your-order'">
                <span class="link-label">{{ $t('hamburgerMenu.follow-your-order') }}</span>
              </router-link>
            </li>
            <li class="level1">
              <router-link class="nav-link-text has-children" :to="'time-of-delivery'">
                <span class="link-label">{{ $t('hamburgerMenu.time-of-delivery') }}</span>
              </router-link>
            </li>
            <li class="level1">
              <router-link class="nav-link-text has-children" :to="'returns-and-refunds'">
                <span class="link-label">{{ $t('hamburgerMenu.returns-and-refunds') }}</span>
              </router-link>
            </li>
            <li class="level1">
              <router-link class="nav-link-text has-children" :to="'payment'">
                <span class="link-label">{{ $t('hamburgerMenu.payment') }}</span>
              </router-link>
            </li>
          </ul>
        </b-collapse>
        <!-- <button class="btn btn-none bcm-link bcm-link-bolded customer-care-item" @click.prevent="goToContactUs">
          <i class="icomoon-icon icon-headphones"></i> {{ $t('hamburgerMenu.customerCare') }}
        </button> -->
        <!--<router-link :to="{name: 'ContactUs'}" class="bcm-link bcm-link-bolded">
          <i class="icomoon-icon icon-headphones"></i>
          <span class="text">{{ $t('hamburgerMenu.customerCare') }}</span>
        </router-link>-->
      </li>
      <li class="level0">
        <router-link :to="{name: 'Boutiques'}" class="bcm-link bcm-link-bolded">
          <i class="icomoon-icon icon-store"></i>
          <span class="text">{{ $t('hamburgerMenu.storeLocator') }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { find } from 'lodash'

export default {
  name: 'InternalLink',
  data () {
    return {
      languageValue: ''
    }
  },
  computed: {
    currentCountry () {
      return this.$store.state.ui.currentCountry
    },
    currentStoreView () {
      return this.$store.state.ui.storeViewCode
    },
    currentLanguage () {
      let currentLang = null
      if (this.currentCountry && this.currentCountry.locales) {
        currentLang = find(this.currentCountry.locales, { store_view: this.currentStoreView })
      }
      return currentLang
    }
  },
  methods: {
    goToAccountPage () {
      let componentToLoad
      if (this.$store.state.user.token) {
        componentToLoad = import('theme/components/Account/AccountNavigation')
      } else {
        componentToLoad = import('theme/components/Auth/Login')
      }
      this.$store.dispatch('ui/loadLeftActiveComponent', { component: componentToLoad, props: { headerTitle: 'Account' } })
    },
    goToShipping () {
      const componentToLoad = import('theme/components/Language/CountriesList')
      return this.$store.dispatch('ui/loadLeftActiveComponent', {
        component: componentToLoad,
        props: {
          headerTitle: this.$t('hamburgerMenu.shipping')
        }
      })
    },
    goToContactUs () {
      return this.$store.dispatch('cms/loadContent', 'contact-us-header').then(res => {
        const componentToLoad = import('theme/components/Header/ContactUs')
        this.$store.dispatch('ui/loadLeftActiveComponent', {
          component: componentToLoad,
          props: {
            headerTitle: this.$t('hamburgerMenu.contactUs')
          }
        })
      })
    },
    redirectLocale ($event) {
      let baseUrl = $event.target.value
      if (baseUrl) window.location.href = `${baseUrl}`
    }
  }
}
</script>
