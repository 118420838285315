<template>
  <div class="menu-form-search form-search-wrap">
    <b-form class="form-search" @submit.prevent="submitForm" :style="styleSearch">
      <b-form-group id="searchText" class="mb-0">
        <base-input
          autocomplete="off"
          classInput="form-search__input"
          name="searchTextInput"
          v-model.trim="inputText"
          :placeholder="$t('Search')"
          type="text"
          @input="submitForm"
          :autofocus="true">
        </base-input>
        <div
          class="form-suggestions-results"
          :class="{ 'no-text': inputText === '', 'no-results': noResults }"
        >
          <!-- loader -->
          <absolute-loader :showLoader="loading" minHeight="auto"></absolute-loader>
          <!-- total items -->
          <!-- <div class="form-suggestions-results_totals" v-show="inputText && totalCount !== undefined">
            <span class="form-suggestions-results_totals_text">{{ $t('items {totalCount}', { totalCount }) }}</span>
          </div> -->
          <!-- Category -->
          <!-- <ul class="form-suggestions-results_categories" >
            <li
              v-for="category in suggestionsCategory"
              :key="category.value"
            >
            <router-link
              :to="category.to"
              class="aqz-link aqz-link-underline"
              @click.native="resetSearch">
                {{category.label}}
            </router-link>
            </li>
          </ul> -->

          <!-- Products -->
          <!-- <div class="form-suggestions-results_products_title my-3" v-show="suggestionsProduct.length">
            {{ $t('ProductSuggestions') }}
          </div> -->
          <div class="container-fluid">
            <ul class="form-suggestions-results_products row" >
              <li
                v-for="product in suggestionsProduct"
                :key="product.id"
                class="col-6"
              >
              <router-link :to="product.to" @click.native="resetSearch" class="text-decoration-none">
                <article>
                  <img-product
                    :imgPath="product.small_image.url"
                    :alt="product.small_image.label"
                    :styleImage="{ maxWidth: '100%' }"
                  />
                  <div class="article_name">
                    <h2 class="card-product__info__intro__link__name">{{product.name}}</h2>
                  </div>
                </article>
              </router-link>
              </li>
            </ul>
          </div>
          <!-- button show all -->
          <div class="text-center mt-3" v-show="suggestionsProduct.length">
            <b-button variant="primary" :to="{ name: 'search', query: { q: inputText }}" @click.native="resetSearch">
              {{ $t('All search results') }}
            </b-button>
          </div>
        </div><!-- END form-suggestions-results -->
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import FormSearch from 'theme/components/Header/FormSearch'
import BaseInput from '@/components/Form/BaseInput'

export default {
  name: 'MenuSearch',
  mixins: [FormSearch],
  components: {
    BaseInput
  }
}
</script>
